<template>
	<article
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
		}"
		class="vacancy"
	>
		<header class="vacancy-header">
			<div class="title-details-wrapper">
				<h2 class="vacancy-title" v-html="vacancyData.title"/>
				<div v-if="!mobileMode && vacancyData.place && vacancyData.employment" class="details" >
					<span v-if="vacancyData.place" class="placement">{{ vacancyData.place }}</span>
					<span v-if="vacancyData.employment" class="employment">{{ vacancyData.employment }}</span>
				</div>
			</div>
			<section class="short-description" v-html="vacancyData.short_description" />
		</header>


		<section
			:class="{
				'shrinked': !descOpenState,
			}"
			class="full-description"
		>
			<div
				ref="descWrapper"
				:style="{
					'--height': animationRunning && descHeight,
				}"
				class="wrapper"
				@transitionend="transitionEndHandler"
			>
				<div
					ref="descContent"
					class="content"
					v-html="vacancyData.full_description"
				/>
			</div>
			<button
				class="details-button"
				@click="detailsButtonClickHandler"
			>
				<svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 12 8">
					<use :xlink:href="`#v-arrow-${descOpenState ? 'up' : 'down'}`" />
				</svg>
				{{ descOpenState ? $t('vacancy.hide_details') : $t('vacancy.show_details') }}
			</button>
		</section>
	</article>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
	name: 'Vacancy',

	props: {
		vacancyData: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			descOpenState: false,
			descHeight: null,
			animationRunning: false,
		};
	},

	computed: {
		...mapGetters('browser', ['mobileMode']),
	},

	methods: {
		transitionEndHandler(event) {
			this.animationRunning = false;
		},

		detailsButtonClickHandler() {
			this.animationRunning = true;
			const openState = this.descOpenState;
			this.$nextTick(() => {
				this.descHeight = `${this.$refs.descContent.clientHeight}px`;
				this.descOpenState = !openState;
			});
		},
	}
};
</script>

<style lang="sass" scoped>
.vacancy


.vacancy-header
	box-sizing: border-box
	.mobile &
		margin-bottom: 25px
	.desktop &
		display: flex
		margin-bottom: 40px


.title-details-wrapper
	.mobile &
	.desktop &
		display: flex
		flex-direction: column

		width: 320px
		flex-basis: 320px
		flex-shrink: 0


.vacancy-title
	margin: 0
	.mobile &
		@extend %h2-mobile-card-title
		margin-bottom: 25px

	.desktop &
		@extend %h2-desktop
		margin-bottom: auto


.details
	.mobile &
		@extend %p-mobile

	.desktop &
		margin-top: 90px
		@extend %small-regular-desktop

.placement
	display: block
	font-weight: bold


.employment
	display: block
	color: #6d7278


.short-description
	max-width: 1024px
	color: #6d7278

	.mobile &
	.desktop &
		margin-left: 40px
		padding: 0 40px



.full-description
	max-width: 1024px
	will-change: background-color, margin-top
	position: relative
	background-color: #f5f5f5
	transition: background-color .2s ease, margin-top .2s ease

	&.shrinked
		background-color: none

	.mobile &
		box-sizing: border-box
		margin-left: -25px
		width: calc(100% + 50px)
		padding: 0 25px

		&.shrinked
			margin-top: 50px


	.desktop &
		margin-left: 360px
		padding: 0 40px

		&.shrinked
			margin-top: 80px


.wrapper
	overflow: hidden
	will-change: height
	height: unset
	height: var(--height, unset)
	transition: height .2s ease

	.shrinked &
		height: 0



.content
	transition: opacity .2s ease

	.mobile &
		padding: 25px 0 100px

	.desktop &
		padding: 30px 0 102px

	.shrinked &
		opacity: 0
		pointer-events: none


.details-button
	@extend %clean-button
	will-change: bottom
	display: inline-flex
	justify-content: flex-start
	align-items: center

	transition: bottom .2s ease


	.mobile &
		@extend %p-mobile
		position: absolute
		bottom: 40px
		left: 25px

	.desktop &
		@extend %p-desktop
		position: absolute
		bottom: 25px
		left: 40px

	.mobile .shrinked &
		bottom: 0

	.desktop .shrinked &
		bottom: 0

	& > .icon
		width: 12px
		height: 8px

		.mobile &
			margin-right: 10px

		.desktop &
			margin-right: 13px


.short-description,
.content
	&::v-deep p
		// text-indent: 2em
		margin: 0
		padding: 0
		// white-space: pre-wrap // preserves all \s

		.mobile &
			@extend %p-mobile
			&:not(:last-child)
				margin-bottom: 25/14 * 1em

		.desktop &
			@extend %p-desktop
			&:not(:last-child)
				margin-bottom: 34/18 * 1em

	&::v-deep nobr
		white-space: nowrap

	&::v-deep .margined_links a
		margin-right: 1.5em
</style>
