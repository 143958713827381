/**
 * Mixin contains `pageData` prop
 * and `page` computed value.
 */

import { mapState, mapGetters } from 'vuex';
import { cleanOfBlockTags } from '@/utilites.js';


export default {
	props: {
		/**
		 * If the page is rendering on landing
		 * it should get `page` prop.
		 */
		pageData: {
			type: Object,
			default: null,
		},
	},

	computed: {
		...mapState(['route']),
		...mapGetters('browser', [ 'mobileMode' ]),

		/**
		 * If the page is standalone we can get page data
		 * from store.
		 */
		page() {
			if (this.pageData) return this.pageData;

			try {
				return this.$store.state.pages.list.find(page => {
					return page.meta.type === this.route.meta.pageType
						&& page.meta.slug === this.route.name;
				});
			} catch (error) {
				console.error('Attempt to get page data from store ends with error.', error)
				return null;
			}
		},

		slug() {
			return this.page.meta.slug;
		},

		standalone() {
			return this.route.meta.standalone;
		},

		title() {
			return cleanOfBlockTags(this.page.title);
		},
	},

	mounted() {
		this.$emit('mounted');
	},
};
