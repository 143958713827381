<template>
	<section
		:id="slug"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
			'standalone': standalone,
		}"
		class="page-join"
	>
		<header class="page-header">
			<h1 class="page-title" v-html="title" />
		</header>

		<div class="vacancies-wrapper">
			<template v-for="(vacancy, index) in vacancies">
				<vacancy
					:key="`vacancy-${index}`"
					:vacancy-data="vacancy"
				/>

				<span
					v-if="index < vacancies.length - 1"
					:key="`separator-${index}`" class="separator"
				/>
			</template>
		</div>



		<div class="sharing-wrapper">
			<p class="sharing-message">{{$t('vacancy.share_vacancies')}}</p>
			<social-sharing-links
				class="sharing-widget"
				:title="title"
				:description="page.meta.search_description"
				:quote="page.meta.search_description"
				horizontal
			/>
		</div>


		<svg v-show="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<defs>
				<symbol id="v-arrow-up" viewBox="0 0 12 8">
					<path
						vector-effect="non-scaling-stroke"
						d="M 1,7 6,2 11,7"
						stroke-width="2"
						stroke="currentColor"
						fill="none"
					/>
				</symbol>
				<symbol id="v-arrow-down" viewBox="0 0 12 8">
					<path
						vector-effect="non-scaling-stroke"
						d="M 1,1 6,6 11,1"
						stroke-width="2"
						stroke="currentColor"
						fill="none"
					/>
				</symbol>
			</defs>
		</svg>
	</section>
</template>

<script>
import { cleanOfBlockTags } from '@/utilites.js';
import { mapGetters } from 'vuex';
import subpageMixin from './subpage-mixin';
import Vacancy from '@/components/vacancy.vue';
import SocialSharingLinks from '@/components/social-sharing-links.vue';



export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin,
	],

	name: 'PageJoinUs',

	components: {
		Vacancy,
		SocialSharingLinks
	},


	props: {
		// `pageData` via subpage mixin
	},


	computed: {
		// [page, title, slug] via subpage mixin

		...mapGetters('browser', ['mobileMode']),

		vacancies() {
			return this.page.vacancies.map(vacancy => ({
				id: vacancy.id,
				title: cleanOfBlockTags(vacancy.title),
				place: vacancy.place,
				employment: vacancy.employment,
				short_description: vacancy.short_description,
				full_description: vacancy.full_description,
			}));
		},
	},
};
</script>

<style lang="sass" scoped>
.page-join
	@extend %page !optional

	&.mobile
		@extend %page-mobile

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop

		&.standalone
			@extend %page-desktop-standalone

.page-header
	@extend %page-header !optional

	.mobile &
		@extend %page-header-mobile

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop

	.desktop.standalone &
		@extend %page-header-desktop-standalone

.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop


.vacancies-wrapper
	width: 100%
	position: relative

	.separator
		display: block
		border-bottom: 1px solid #dddddd

		.mobile &
			margin: 25px 0

		.desktop &
			margin: 40px 0

.sharing-wrapper
	display: flex
	align-items: center
	background-color: #ffffff
	position: sticky
	bottom: 0px
	margin-top: 80px

	&::before
		content: ''
		display: block
		pointer-events: none
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		border: solid 1px lighten($clr__gray, 30%)
		box-sizing: border-box

.sharing-message
	color: $clr__gray
	margin: 0 40px
	@media (max-width: 768px)
		display: none
</style>
