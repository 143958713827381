
/**
 * Open share link in new window
 */
function openPopup(url, w, h) {
	window.open(url);
}

/**
 * Copy text to the clipboard
 */
function copyText(value) {
	let textarea = document.createElement("textarea");
	textarea.style.cssText = "position:fixed;pointer-events:none;z-index:-9999;opacity:0;";
	textarea.value = value;
	document.body.appendChild(textarea);
	textarea.select();
	try {
		document.execCommand("copy");
	} catch (err) {
		console.warn(err);
	}
	document.body.removeChild(textarea);
}

export const formatStringToParam = value => encodeURIComponent(value.replace(/&amp;/g, "&"))

export const getSocialSharingLinks = config => {
	return [
		{
			type: "link",
			url: "",
			event: () => copyText(config.url)
		},
		{
			type: "ok",
			url: `https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${config.url}&st.comments=${config.description}`,
			event: () =>
				openPopup(
					`https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=${config.url}&st.comments=${config.description}`
				)
		},
		{
			type: "tg",
			url: `https://t.me/share/url?url=${config.url}&text=${config.description}`,
			event: () =>
				openPopup(`https://t.me/share/url?url=${config.url}&text=${config.description}`)
		},
		{
			type: "tw",
			url: `https://twitter.com/intent/tweet?text=${config.title}&url=${config.url}&hashtags=${config.tags}`,
			event: () =>
				openPopup(
					`https://twitter.com/intent/tweet?text=${config.title}&url=${config.url}&hashtags=${config.tags}`
				)
		},
		// {
		// 	type: "fb",
		// 	url: `https://www.facebook.com/sharer/sharer.php?u=${config.url}&title=${config.title}&description=${config.description}&quote=${config.quote}&hashtag=${config.tags}`,
		// 	event: () =>
		// 		openPopup(
		// 			`https://www.facebook.com/sharer/sharer.php?u=${config.url}&title=${config.title}&description=${config.description}&quote=${config.quote}&hashtag=${config.tags}`
		// 		)
		// },
		{
			type: "vk",
			url: `https://vk.com/share.php?url=${config.url}&title=${config.title}&description=${config.description}&image=${config.media}`,
			event: () =>
				openPopup(
					`https://vk.com/share.php?url=${config.url}&title=${config.title}&description=${config.description}&image=${config.media}`
				)
		}
	];
};
