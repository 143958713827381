import { render, staticRenderFns } from "./social-sharing-links.vue?vue&type=template&id=3dcb4a0b&scoped=true&"
import script from "./social-sharing-links.vue?vue&type=script&lang=js&"
export * from "./social-sharing-links.vue?vue&type=script&lang=js&"
import style0 from "./social-sharing-links.vue?vue&type=style&index=0&id=3dcb4a0b&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcb4a0b",
  null
  
)

export default component.exports