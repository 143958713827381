var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"vacancy",class:{
		'mobile': _vm.mobileMode,
		'desktop': !_vm.mobileMode,
	}},[_c('header',{staticClass:"vacancy-header"},[_c('div',{staticClass:"title-details-wrapper"},[_c('h2',{staticClass:"vacancy-title",domProps:{"innerHTML":_vm._s(_vm.vacancyData.title)}}),(!_vm.mobileMode && _vm.vacancyData.place && _vm.vacancyData.employment)?_c('div',{staticClass:"details"},[(_vm.vacancyData.place)?_c('span',{staticClass:"placement"},[_vm._v(_vm._s(_vm.vacancyData.place))]):_vm._e(),(_vm.vacancyData.employment)?_c('span',{staticClass:"employment"},[_vm._v(_vm._s(_vm.vacancyData.employment))]):_vm._e()]):_vm._e()]),_c('section',{staticClass:"short-description",domProps:{"innerHTML":_vm._s(_vm.vacancyData.short_description)}})]),_c('section',{staticClass:"full-description",class:{
			'shrinked': !_vm.descOpenState,
		}},[_c('div',{ref:"descWrapper",staticClass:"wrapper",style:({
				'--height': _vm.animationRunning && _vm.descHeight,
			}),on:{"transitionend":_vm.transitionEndHandler}},[_c('div',{ref:"descContent",staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.vacancyData.full_description)}})]),_c('button',{staticClass:"details-button",on:{"click":_vm.detailsButtonClickHandler}},[_c('svg',{staticClass:"icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 12 8"}},[_c('use',{attrs:{"xlink:href":("#v-arrow-" + (_vm.descOpenState ? 'up' : 'down'))}})]),_vm._v(" "+_vm._s(_vm.descOpenState ? _vm.$t('vacancy.hide_details') : _vm.$t('vacancy.show_details'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }