<template>
	<div :class="['social-share', { 'social-share_horizontal': horizontal }]">
		<button
			v-for="(sharing, index) in links"
			:key="sharing.type"
			type="button"
			class="social-share__button"
			:class="sharing.type"
			:title="$t(`social.${sharing.type}`)"
			@click="sharing.event"
		>
			<span class="hidden-captiopn">{{ $t(`social.${sharing.type}`) }}</span>
			<svg class="button__icon">
				<use
					:xlink:href="`#glyph--share-${sharing.type}`"
					:href="`#glyph--share-${sharing.type}`"
				/>
			</svg>
		</button>
	</div>
</template>

<script>
import { getSocialSharingLinks, formatStringToParam } from "@/constants/social-sharing";
import { resolveRelativeStaticURL } from "@/utilites.js";


export default {
	name: "SocialSharingLinks",

	props: {
		// URL to share.
		// url: String,
		title: String,
		description: String,

		// Twitter hashtags
		tags: {
			type: Array,
			default: () => []
		},

		// VK img
		media: String,

		// Facebook quote
		quote: String,

		horizontal: Boolean,
	},
	computed: {
		config() {
			return {
				title: formatStringToParam(this.title || ''),
				quote: formatStringToParam(this.quote || ''),
				description: formatStringToParam(this.description || ''),
				tags: this.tags.toString(),
				url: `https://weareinsports.ru${this.$route.path}`,
				media: this.media ? resolveRelativeStaticURL(this.media) : ""
			};
		},
		links() {
			return getSocialSharingLinks(this.config);
		}
	},
};
</script>

<style lang="sass" scoped>
.social-share
	position: relative
	display: flex
	flex-direction: column

	&_horizontal
		flex-direction: row

	&::after
		content: ''
		display: block
		pointer-events: none
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		border: solid 1px lighten($clr__gray, 30%)
		box-sizing: border-box

.social-share__button
	@extend %clean-button
	width: 50px
	height: 50px
	cursor: pointer
	display: flex
	justify-content: center
	align-items: center
	background: white

	&.link
		background: $clr__yellow
		&:active
			background: white

	&:hover
		background: $clr__yellow

.social-share__button + .social-share__button
	border-top: solid 1px lighten($clr__gray, 30%)

.social-share_horizontal
	.social-share__button + .social-share__button
		border-top: none
		border-left: solid 1px lighten($clr__gray, 30%)


.button__icon
	height: 20px
	width: 24px

.hidden-captiopn
	@extend %visually-hidden
</style>
